
$(window).on("load", function() {
  $(document).ready(function() {
"use strict";

var stripe = Stripe("pk_test_bXsOAsQyUKVQ1m2cRTiJ29WI");

function registerElements(elements, exampleName) {
  var formClass = "." + exampleName;
  var example = document.querySelector(formClass);

  // var form = example.querySelector('form');
  if ($("#form--payment-add").length) {
    var form = document.getElementById("form--payment-add");
  } else if ($("#form--create-ad-pay").length) {
    var form = document.getElementById("form--create-ad-pay");
  }

  // var resetButton = example.querySelector('a.reset');
  // var resetButton = document.getElementById('form--payment-reset');
  // var error = form.querySelector('.error');
  var error = document.getElementById("error");
  var errorMessage = error.querySelector(".message");

  function stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    enableInputs();
    if ($("#form--payment-add").length) {
      var form = document.getElementById("form--payment-add");
    } else if ($("#form--create-ad-pay").length) {
      var form = document.getElementById("form--create-ad-pay");
    }
    var hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "stripeToken");
    hiddenInput.setAttribute("value", token.id);
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }

  function enableInputs() {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function(input) {
        input.removeAttribute("disabled");
      }
    );
  }

  function disableInputs() {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function(input) {
        input.setAttribute("disabled", "true");
      }
    );
  }

  function triggerBrowserValidation() {
    // The only way to trigger HTML5 form validation UI is to fake a user submit
    // event.
    var submit = document.createElement("input");
    submit.type = "submit";
    submit.style.display = "none";
    form.appendChild(submit);
    submit.click();
    submit.remove();
  }

  // Submit Card to save
  function stripeOnForm(e, form, example) {
    e.preventDefault();

    // Trigger HTML5 validation UI on the form if any of the inputs fail
    // validation.
    var plainInputsValid = true;
    Array.prototype.forEach.call(form.querySelectorAll("input"), function(
      input
    ) {
      if (input.checkValidity && !input.checkValidity()) {
        plainInputsValid = false;
        return;
      }
    });
    if (!plainInputsValid) {
      triggerBrowserValidation();
      return;
    }

    // Show a loading screen...
    example.classList.add("submitting");

    // Disable all inputs.
    disableInputs();

    // Gather additional customer data we may have collected in our form.
    var name = $("#card_name").val();
    var address1 = $("#card_address").val();
    var city = $("#card_town").val();
    var zip = $("#card_postcode").val();
    var additionalData = {
      name: name ? name.value : undefined,
      address_line1: address1 ? address1.value : undefined,
      address_city: city ? city.value : undefined,
      address_zip: zip ? zip.value : undefined
    };

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token. We can also pass
    // in the additional customer data we collected in our form.
    stripe.createToken(elements[0], additionalData).then(function(result) {
      // Stop loading!
      example.classList.remove("submitting");

      if (result.token) {
        // If we received a token, submit the token to the stripeTokenHandler.
        stripeTokenHandler(result.token);
      } else {
        // Otherwise, un-disable inputs.
        enableInputs();
      }
    });
  }

  // Listen for errors from each Element, and show error messages in the UI.
  var savedErrors = {};
  elements.forEach(function(element, idx) {
    element.on("change", function(event) {
      if (event.error) {
        error.classList.add("visible");
        savedErrors[idx] = event.error.message;
        errorMessage.innerText = event.error.message;
      } else {
        savedErrors[idx] = null;

        // Loop over the saved errors and find the first one, if any.
        var nextError = Object.keys(savedErrors)
          .sort()
          .reduce(function(maybeFoundError, key) {
            return maybeFoundError || savedErrors[key];
          }, null);

        if (nextError) {
          // Now that they've fixed the current error, show another one.
          errorMessage.innerText = nextError;
        } else {
          // The user fixed the last error; no more errors.
          error.classList.remove("visible");
        }
      }
    });
  });

  // Listen on the form's 'submit' handler...
  form.addEventListener("submit", function(e) {
    if ($("#select_payment_card").length) {
      if ($("#select_payment_card").val() == "add_new") {
        stripeOnForm(e, form, example);
      } else {
        // Allow form to submit without new card field details
      }
    } else {
      stripeOnForm(e, form, example);
    }
  });
}

if ($("#form--payment-add").length || $("#form--create-ad-pay").length) {
  (function() {
    "use strict";

    var elements = stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Nunito"
        }
      ],
      // Stripe's examples are localized to specific languages, but if
      // you wish to have Elements automatically detect your user's locale,
      // use `locale: 'auto'` instead.
      locale: window.__exampleLocale
    });

    var elementStyles = {
      base: {
        color: "#333",
        fontWeight: 500,
        fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
        fontSize: "1rem",
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "#ccc"
        },
        ":-webkit-autofill": {
          color: "#e39f48"
        }
      },
      invalid: {
        color: "#E25950",

        "::placeholder": {
          color: "#FFCCA5"
        }
      }
    };
    var elementClasses = {
      focus: "focus",
      empty: "empty",
      invalid: "invalid"
    };

    var cardNumber = elements.create("cardNumber", {
      style: elementStyles,
      classes: elementClasses
    });
    cardNumber.mount("#card_number");

    var cardExpiry = elements.create("cardExpiry", {
      style: elementStyles,
      classes: elementClasses
    });
    cardExpiry.mount("#card_expiry");

    var cardCvc = elements.create("cardCvc", {
      style: elementStyles,
      classes: elementClasses
    });
    cardCvc.mount("#card_cvc");

    if ($("#form--payment-add").length) {
      registerElements([cardNumber, cardExpiry, cardCvc], "form--payment-add");
    } else if ($("#form--create-ad-pay").length) {
      registerElements(
        [cardNumber, cardExpiry, cardCvc],
        "form--create-ad-pay"
      );
    }
  })();
}
});
});
