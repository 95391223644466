$(window).on("load", function() {
    $(document).ready(function() {
        if($('#booking-form-basic').length) {
            $('#course-name').val(booking_details.courseName);
            $('#course-date').val(booking_details.courseDate);
            $('#course-start').val(booking_details.courseStart);
            $('#course-end').val(booking_details.courseEnd);
        }
    });
});
