$(window).on("load", function() {


  $(document).ready(function() {

      setTimeout(function () {
          $('#myModal').foundation('open');
      }, 1000);

    // Store the window width
    var windowWidth = $(window).width();

    // Resize Event
    $(window).resize(function() {
      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
      if ($(window).width() != windowWidth) {
        // Update the window width for next time
        windowWidth = $(window).width();

        positionHeaderTransition();
      }

      // Otherwise do nothing
    });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      $("#mobile-cta").removeClass("hidden");
    }

    if($('#display-location-map-1').length) {
    initMap();
    }

    if($('#display-location-map-2').length) {
        initMap2();
    }

    if($('#display-location-map-3').length) {
        initMap3();
    }

    if($('#display-location-map-4').length) {
        initMap4();
    }

  });
});

// console.log($(window).width());

$(document).ready(function() {
  $(".site-menu--close").click(function() {
    $("body").removeClass("site-menu--is-open");
  });

  $(".aw-menu-icon").click(function() {
    $("body").addClass("site-menu--is-open");
  });

  $(".contact_tab_link").click(function(event) {
    event.preventDefault();
    var tabToShow = $(this).data("tab-to-open");
    $("#example-tabs").foundation("selectTab", tabToShow);
  });
});

var widgetCSS = "" + ".timeline-Widget{background-color: transparent;}";

function paint() {
  var w = document.getElementById("twitter-widget-0").contentDocument;

  var s = document.createElement("style");
  s.innerHTML = widgetCSS;
  s.type = "text/css";
  w.head.appendChild(s);
}

$(document).ready(function() {
  $(".organisation-testimonial-slide").slick({
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1
  });
});

$(".home-hero--slick").slick({
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1
});

let originalSize = $(".who-we-work-with-container").height();
let innerSize = $(".who-we-work-with-inner").height();

$(".who-we-work-with-button").on("click", function() {
  let item = $(".who-we-work-with-container");

  if (item.height() < innerSize) {
    let height = item.height();

    console.log(height + originalSize);
    item.css("height", height + originalSize);
  }
});
let ajaxurl = "https://" + document.domain + "/wp/wp-admin/admin-ajax.php";

console.log(ajaxurl);

if (document.domain === "localhost") {
  ajaxurl = "https://" + document.domain + ":3000/wp/wp-admin/admin-ajax.php";
}

function getStudentInformationAJAX() {
  $(".student-information").each(function() {
    let self = $(this);

    $(this)
      .children(".student-dropdown")
      .on("change", function() {
        $.ajax({
          url: ajaxurl,
          type: "POST",
          data: {
            action: "getStudentInformation",
            user_id: userid,
            student_id: $(this).val()
          },
          success: function(result) {
            self.find(".email").val(result.email);
            self.find(".first_name").val(result.first_name);
            self.find(".last_name").val(result.last_name);
            self.find(".dietary_requirements").val(result.dietary_requirements);
            self.find(".access_requirements").val(result.access_requirements);
            self.find(".job_title").val(result.job_title);
          },

          error: function(xhr, status, error) {
            console.log(error, status, xhr);
          },
          dataType: "json"
        });
      });
  });
}

$(document).ready(function() {
  let personCount = 1;

  getStudentInformationAJAX();

  try {
    if (spacesLeft < 2) {
      $(".add-user").remove();
    }

    $(".add-user").on("click", function(e) {
      if (spacesLeft >= 2) {
        spacesLeft--;

        personCount++;

        let html = $(".template").html();
        let student = $("#student-container").append(html);

        let addedStudent = $(".student-information").last();

        addedStudent
          .find(".count")
          .empty()
          .text(personCount);

        addedStudent.find(".text-box").each(function() {
          let val = $(this).attr("name");
          $(this).attr("name", val + "-" + personCount);
        });

        getStudentInformationAJAX(); //call again to inform the dom that there is a new element.

        calculatePrice();
      }
    });
  } catch (e) {}

  $(document).on("click", ".remove-user", function() {
    spacesLeft++;

    personCount--;

    $(this)
      .parent()
      .remove();

    var price = $(".calc-price").attr("value");
    var price = price * personCount;
    $(".price-inner").text(price / 100);
  });

  let currentStage = 1;

  function getStage(i) {
    if (!$(".stage-text-4").hasClass("active")) {
      let previousStage = currentStage + 1;

      if (i === undefined) {
        previousStage = currentStage - 1;
      }

      $(".stage-text-" + previousStage).removeClass("active");
      $(".stage-text-" + currentStage).addClass("active");

      $(".stage-" + previousStage).hide();
      $(".stage-" + currentStage).show();
    }
  }

  getStage();

  function calculatePrice() {
    let price = $(".calc-price").attr("value") / 100;

    let accreditedPrice = parseFloat($(".accredited_price").val()) / 100;

    let accreditDiff = Math.abs(price - accreditedPrice).toFixed(2);

    let totalAccredited = $(".stage-2").find("input.accredit-select:checked")
      .length;

    $(".total-accredited").val(totalAccredited);

    let accreditTotal = accreditDiff * totalAccredited;

    let calc = price.toFixed(2) * personCount + accreditTotal;

    if (totalAccredited === 0) {
      calc = price.toFixed(2) * personCount;
    }

    $(".price-inner").text(calc);
  }

  $("body").on("click", ".next-stage", function(e) {
    calculatePrice();

    if ($(this).attr("disabled") !== "disabled") {
      let valid = true;

      let validator = $(".form--payment-add").validate();
      $(".stage-" + currentStage)
        .find("input[req], input[required]")
        .each(function() {
          $(this).rules("add", {
            required: true
          });

          if (validator.element($(this)) == false) {
            valid = false;
          }
        });
      if (valid) {
        if (currentStage < 3) {
          currentStage++;
        }
        getStage();
      }
    } else {
      $(".privacy").addClass("error");
    }
  });

  $(".previous-stage").on("click", function() {
    currentStage--;

    getStage("previous");
  });

  function privacyPolicyCheck() {
    if ($(".privacy-policy").is(":checked")) {
      $(".next-stage").removeAttr("disabled");
    } else {
      $(".next-stage").attr("disabled", "true");
    }
  }

  $(".privacy-policy").click(function() {
    privacyPolicyCheck();
  });

  privacyPolicyCheck();

  $('[name="same-as-main-details"]').on("click", function() {
    if ($(this).is(":checked")) {
      $(".billing-address").hide();

      $(".billing-address input").each(function(e) {
        $(this).removeAttr("required");
      });
    } else {
      $(".billing-address").show();
      $(".billing-address input").each(function(e) {
        $(this).attr("required", "required");
      });
    }
  });

  $(".make-payment").on("click", function() {
    let personCount = $("#student-container .student-information").length;
    $(".total-students").val(personCount);
  });

  $("#select_payment_card").on("change", function() {
    if ($("#select_payment_card").val() !== "add_new") {
      $(".card-details-container").hide();

      $(".stripe--new-card-field").each(function() {
        $(this).prop("disabled", true);
      });
    } else {
      $(".card-details-container").show();
    }
  });
});

$(".refund").on("click", function() {
  let id = $(this).attr("aria-id");

  $('input[name="student_id"]').val(id);

  $(".confirm-message").text(
    "<b>Note:</b> Removing this student will submit a refund."
  );
});

$(".refund").on("click", function() {
  let id = $(this).attr("aria-id");

  $('input[name="student_id"]').val(id);

  $(".confirm-message").html(
    "<b>Note:</b> Removing this student will submit a refund."
  );

  $(".submit-refund").removeClass("full");
});

$(".cancel-booking").on("click", function() {
  $('input[name="booking_id"]').attr("id");

  $(".confirm-message").html("<b>Note:</b> A full refund will be issued.");

  $(".submit-refund").addClass("full");
});

$(".submit-refund").on("click", function() {
  if ($(this).hasClass("full")) {
    $(".cancel-booking").submit();
  } else {
    $(".refund-form").submit();
  }
});

$(".individual-label").on("click", function() {
  $(".individual-toggle").show();
  $(".organisation-toggle").hide();
});
$(".organisation-label").on("click", function() {
  $(".individual-toggle").hide();
  $(".organisation-toggle").show();
});

try {
  if (courseName) {
    $(document).ready(function() {
      $('input[name="course-name"]').val(courseName);
    });
  }
} catch (e) {}

function toggleLink(checkBox) {
  var link = document.getElementById("agreeLink");

  if (checkBox.checked) link.style.display = "inline";
  else link.style.display = "none";
}

$('select[name="user_federation"]').on("change", function() {
  if ($(this).val() === "organisation") {
    $('label[for="sector"]').show();
    $(".volunteer-message").hide();
    $('select[name="sector"]').val('');
  } else {
    $('label[for="sector"]').hide();
    $('select[name="sector"]').val('voluntary')
  }

  if ($(this).val() === "individual") {
    //
    $(".volunteer-message")
      .show()
      .html("I am individual registering on behalf of myself");
  }
});

$(".volunteer-message").hide();
$('select[name="sector"]').on("change", function() {
  if ($(this).val() === "voluntary") {
    $(".volunteer-message")
      .show()
      .html(
        "I am registering on behalf of an organisation I work for in the Voluntary, Community & Social Enterprise Sector"
      );
  } else if ($(this).val() === "public") {
    $(".volunteer-message")
      .show()
      .text(
        "I am registering on behalf of an organisation I work for in the Public/Private Sector"
      );
  } else {
    $(".volunteer-message").hide();
  }
});

$(".download-certificate").on("click", function() {
  let booking = $(this).attr("data-booking");

  let student = $(this).attr("data-student");

  $.ajax({
    url: ajaxurl,
    type: "POST",
    data: {
      action: "getCertificate",
      booking_id: booking,
      student_id: student
    },
    success: function(result) {},

    error: function(xhr, status, error) {
      console.log(error, status, xhr);
    },
    dataType: "json"
  });
});

$(window).on("load", function() {
    $(document).ready(function() {
        if($('#aw-contact-form').length) {
            $('#page-title').val(contact_form_details.currentPage);
        }
    });
});

var handler = StripeCheckout.configure({
  key: 'pk_test_uefPSjmAjAQe3o8fZSlqr8Sa',
  token: function(token) {
    $("#stripeToken").val(token.id);
    $("#stripeEmail").val(token.email);
    $("#amountInCents").val(Math.floor($("#amountInPounds").val() * 100));
    $("#myForm").submit();
  }
});

$('#customButton').on('click', function(e) {
  var amountInCents = Math.floor($("#amountInPounds").val() * 100);
  var displayAmount = parseFloat(Math.floor($("#amountInPounds").val() * 100) / 100).toFixed(2);
  // Open Checkout with further options
  handler.open({
    name: 'Volunteer Now',
    description: 'Custom amount (£' + displayAmount + ')',
    amount: amountInCents,
  });
  e.preventDefault();
});

// Close Checkout on page navigation
$(window).on('popstate', function() {
  handler.close();
});
