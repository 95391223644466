function setOutcome(result) {
    // var successElement = document.querySelector('.success');
    // var errorElement = document.querySelector('.error');
    // successElement.classList.remove('visible');
    // errorElement.classList.remove('visible');

    if (result.token) {
        // In this example, we're simply displaying the token
        // successElement.querySelector('.token').textContent = result.token.id;
        // successElement.classList.add('visible');

        // Use existing stripeTokenHandler function
        stripeTokenHandler(result.token);

        // In a real integration, you'd submit the form with the token to your backend server
        //var form = document.querySelector('form');
        //form.querySelector('input[name="token"]').setAttribute('value', result.token.id);
        //form.submit();
    } else if (result.error) {
        errorElement.textContent = result.error.message;
        errorElement.classList.add('visible');
    }
}

$(document).ready(function () {

    var can_submit_form_june_2019 = true;

    if($('.student-repeater').length) {
        var repeater = $('.student-repeater').repeater({
            initEmpty: true,
            defaultValues: selected_students,
        });

        populate_repeater(students, repeater);
    }

    if($('.student-repeater--individual').length) {
        var repeater__single = $('.student-repeater--individual').repeater({
            initEmpty: false,
        });
    }

    //
    // Check atleast one student before proceeding - organisation
    //

    $('#step-2--organisation').click(function(event) {
        event.preventDefault();

        if(can_submit == true) {
            $("#form__step-2--organisation")[0].submit();
        }
    });

    //
    // Toggle Dynamic Student
    //

    $('.training-row-wrapper').on('click', '.add-remove-student', function(event){
        event.preventDefault();

        var currentStudent = $(this).data('student');

        if(students[currentStudent].student__selected) {
            // Remove Student Code
            students[currentStudent].student__selected      = false;
            students[currentStudent].student__accredited    = 0;
            $(this).text('Add Student');
        } else {

            // Check there is space left on the course
            var number_students = $('.student-repeater > div > div').length;
            if(number_students < spacesLeft) {
                // Add Student Code
                students[currentStudent].student__selected      = true;
                $(this).text('Remove Student');
            } else {
                alert('There no spaces remaining on this course.');
            }

        }

        populate_repeater(students, repeater);
    });

    //
    // Toggle Is Accredited
    //

    $('.student-repeater').on('click', 'label', function(event){
        event.preventDefault();
        var checkbox = $(this).prev();

        var currentStudent = $(this).data('student');

        if(checkbox.is(':checked')) {
            checkbox.prop('checked', false);
        } else {
            checkbox.prop('checked', true);
        }

        var repeater_val = $('.student-repeater').repeaterVal();

        // check if each selected student is accredited
        for (var i = 0; i < repeater_val.course_students.length; i++) {
            var current_student = repeater_val.course_students[i];

            // if accredited update the students array of student objects
            if(parseInt(current_student['student__accredited']) > 0) {
                for (var i = 0; i < students.length; i++) {
                    if(students[i].student__id == current_student['student__id']) {
                        students[i].student__accredited = 1;
                    }
                }
            }

        }
    });

    // Create new student

    $('#new_student__create').click(function(event) {
        event.preventDefault();

        var this_button = $(this);

        // Disable create button
        $(this).prop('disabled', true);

        // Clear Errors
        $('.student-booking-response').text('');

        // Display loaders.css
        $('.organisation-tabs-section').each(function( index ) {
            $(this).addClass('organisation-tabs-section--loading');
        });

        // We'll pass this variable to the PHP function example_ajax_request
        var fruit = 'Banana';

        var valid_request = true;

        var new_student__first_name             = $('#new_student__first_name').val();
        var new_student__last_name              = $('#new_student__last_name').val();
        var new_student__email                  = $('#new_student__email').val();
        var new_student__job_title              = $('#new_student__job_title').val();
        var new_student__dietary_requirements   = $('#new_student__dietary_requirements').val();
        var new_student__access_requirements    = $('#new_student__access_requirements').val();

        if(new_student__email.length <= 0 || new_student__first_name.length <= 0 || new_student__last_name.length <= 0 || new_student__job_title.length <= 0) {
            // alert('invalid');
            valid_request = false;
            $('.student-booking-response').append('<strong>Error: </strong> Fields with * are required.<br>');
        }

        if(validateEmail(new_student__email) == false) {
            // alert('invalid email');
            valid_request = false;
            $('.student-booking-response').append('<strong>Error: </strong> A valid email is required.<br>');
        }

        if(valid_request == true) {

            // Clear Errors
            $('.student-booking-response').text('');

            // This does the ajax request
            $.ajax({
                url: ajaxurl, // or example_ajax_obj.ajaxurl if using on frontend
                dataType: "json",
                data: {
                    'action':                               'new_student__create',
                    'new_student__first_name':              new_student__first_name,
                    'new_student__last_name':               new_student__last_name,
                    'new_student__email':                   new_student__email,
                    'new_student__job_title':               new_student__job_title,
                    'new_student__dietary_requirements':    new_student__dietary_requirements,
                    'new_student__access_requirements':     new_student__access_requirements,
                },
                success:function(data) {

                    // Add to Students and selected students
                    var student = {
                        student__id:                data.id,
                        student__name:              new_student__first_name + ' ' + new_student__last_name,
                        student__job_title:         new_student__job_title,
                        student__email:             new_student__email,
                        student__accredited:        0,
                        student__selected:          true,
                    };
                    students.push(student);

                    populate_repeater(students, repeater);

                    // remove no students row

                    if($('#no-students-found').length) {
                        $('#no-students-found').remove();
                    }

                    // populate existing student list

                    var new_html_element = '<script></script><div class="event-row training-row"><div class="table-cell">' + new_student__first_name + ' ' + new_student__last_name + '</div><div class="table-cell">' + new_student__job_title + '</div><div class="table-cell">' + new_student__email + '</div><div class="table-cell"><button data-student="' + (students.length - 1) + '" class="add-remove-student btn-aw button purple w-100">Remove Student</button></div></div>';

                    $( ".training-row-wrapper" ).prepend(new_html_element);

                    // clear student form
                    $('#new_student__first_name').val('');
                    $('#new_student__last_name').val('');
                    $('#new_student__email').val('');
                    $('#new_student__job_title').val('');
                    $('#new_student__dietary_requirements').val('');
                    $('#new_student__access_requirements').val('');

                    // Remove loading
                    $('.organisation-tabs-section').each(function( index ) {
                        $(this).removeClass('organisation-tabs-section--loading');
                    });

                    // Enable Create Button
                    this_button.prop('disabled', false);

                },
                error: function(errorThrown){
                    console.log(errorThrown);
                }
            });
        } else {
            // display error message

            setTimeout(function() {
                // Remove loading
                $('.organisation-tabs-section').each(function( index ) {
                    $(this).removeClass('organisation-tabs-section--loading');
                });

                // Enable Create Button
                this_button.prop('disabled', false);

            }, 1000);


        }

    });

    //
    // Stripe Elements Setup
    //

    if($('#aw-payment-form-v2').length) {

        var paymentForm = $('#aw-payment-form-v2');
        var stripe = Stripe(awStripe.S_PK);
        var elements = stripe.elements({
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:200,300,400',
                },
            ],
        });

        // Custom styling can be passed to options when creating an Element.
        var style = {
            base: {
                iconColor: '#666EE8',
                color: '#0a0a0a',
                lineHeight: '16px',
                fontWeight: 300,
                fontFamily: 'Nunito, sans-serif',
                fontSize: '16px',

                '::placeholder': {
                    color: '#CFD7E0',
                    padding: '4px 0 0 0',
                    lineHeight: '22px',
                    fontWeight: 200,
                },
            },
        };

        if($('#card-number-element').length) {
            // Create an instance of the cardNumber Element.
            var cardNumberElement = elements.create('cardNumber', {
                style: style
            });
            // Add an instance of the cardNumber Element into the `card-number-element` <div>.
            cardNumberElement.mount('#card-number-element');

            // Create an instance of the cardExpiry Element.
            var cardExpiryElement = elements.create('cardExpiry', {
                style: style
            });
            // Add an instance of the cardExpiry Element into the `card-expiry-element` <div>.
            cardExpiryElement.mount('#card-expiry-element');

            // Create an instance of the cardCvc Element.
            var cardCvcElement = elements.create('cardCvc', {
                style: style
            });
            // Add an instance of the cardCvc Element into the `card-cvc-element` <div>.
            cardCvcElement.mount('#card-cvc-element');

            cardNumberElement.on('change', function(event) {
                setOutcome(event);
            });

            cardExpiryElement.on('change', function(event) {
                setOutcome(event);
            });

            cardCvcElement.on('change', function(event) {
                setOutcome(event);
            });
        }

        // Create a token or display an error when the form is submitted.
        var form = document.getElementById('aw-payment-form-v2');
        // var form = paymentForm;
        // console.log(can_submit_form_june_2019);
        if(can_submit_form_june_2019 == true) {
            form.addEventListener('submit', function(e) {
                e.preventDefault();

                can_submit_form_june_2019 = false;

                $('#make_payment_button').attr("disabled", true);
                $('#make_payment_button_processing').css('display', 'block');
                $('#make_payment_button').css('display', 'none');

                if($('#card-number-element').length) {
                    var options = {
                        name: document.getElementById('card_holder_name').value,
                        address_zip: document.getElementById('postal_code').value,
                    };
                    stripe.createToken(cardNumberElement, options).then(setOutcome);
                } else {
                    this.submit();
                }
            });
        }
    }

});

// Function to set the jquery.repeater with selected students
function populate_repeater(students, repeater) {

    var selected_students = [];

    can_submit = false;

    for (var index = 0; index < students.length; ++index) {
        if(students[index].student__selected) {
            selected_students.push(students[index]);
            can_submit = true;
        }
    }

    repeater.setList(selected_students);
}

// Function for basic email validation

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

//
// stripeTokenHandler() Function to submit form
//

function stripeTokenHandler(token) {
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById('aw-payment-form-v2');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);

  // Submit the form
  form.submit();
}
